import React from 'react';
import { FiArrowRight } from 'react-icons/fi';

import { ContainerAPresentation, ContainerArea, Container,  Know } from './styles';

import ApresentationImage from '../../images/gatsby-apresentation.png';

const Apresentation = () => {
  
    return (
      <>
    
        <Container>
            <ContainerArea>
              <h1>O aplicativo ideal para o controle de suas comissões</h1>
              <p>Ferramenta que possibilita a antecipação e controle das comissões na palma da mão</p>

              <Know 
                to="video"
                spy={true}
                smooth={true}
                duration= {700}> 
                  Conheça 
                  <FiArrowRight size={30} />
              </Know>
            </ContainerArea>

            <img 
                src={ApresentationImage}
                alt="Strategi App"
            />
          </Container>
      </>
    )
  };
  
  export default Apresentation;
  