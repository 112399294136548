import styled from 'styled-components';
import { Link } from "react-scroll";

export const Container = styled.section`
    background: #173746;
    display: flex;
    align-content:center;
    justify-content: center;
    
    img {
        margin-left: 10vh;
        margin-bottom: 80px;
        margin-top:70px;

        @media (max-width: 500px) {
            position: absolute;
            width:60%;
            margin-left: 40px;
            z-index: 0;
            margin-top:30px;
        }
    }

    @media (max-width: 500px) {
        align-content:left;
        justify-content: left;
    }
`;

export const ContainerArea = styled.div`
    flex-direction: column;
    margin-left: 130px;
   
    h1 {
        max-width: 561px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800 bold;
        font-size: 56px;
        line-height: 67px;
        color: #FFFFFF;
        margin: 130px 0 20px;

        @media (max-width: 768px) {
            font-size: 28px;
            line-height: 34px;
            margin: 50px 0 20px;

        }
    }

    p {
        max-width: 354px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #FFFFFF;
        opacity: 0.5;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }

    @media (max-width: 768px) {
        z-index: 1;
        margin-left: 40px;
        max-width: 281px;
        margin-top: 220px;
    }
`;


export const Know = styled(Link)`
    display: flex;
    color: #80F5E4;
    align-items: center;
    transition: transform 0.2s;
    text-transform: uppercase;
    cursor: pointer;

    padding-top: 21px;
    margin-bottom: 60px;

    @media (max-width: 768px) {
        padding-top: 35px;
        margin-bottom: 45px;
    }

    &:hover {
        transform: translateX(10px);
    }

    svg {
        margin: auto 1vh;
        color: #80F5E4;
    }
`;